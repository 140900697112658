<template>
  <div>
    <div style="padding: 1em">
      <h1>A11 Transcript tools</h1>
    </div>
    <div style="padding: 1em">
      <h2>Get YouTube transcript</h2>
      <input
        v-model="videoUrl"
        type="text"
        placeholder="Paste a YouTube URL"
        :disabled="importing"
      >
      <br>
      <ProgressSpinner v-if="importing" />
      <span v-if="importing">Importing...</span>
      <span v-if="importError">{{ importError }}</span>
    </div>
    <div style="padding: 1em">
      <h2>Fix Dropbox Transcript</h2>
      <a href="/remove-dropbox-timestamps.html">Click here</a>
    </div>
  </div>
</template>
  <script lang="ts">
  import Vue from 'vue';
  import axios from 'axios'
  import VueYouTubeEmbed from 'vue-youtube-embed'
import ProgressSpinner from '@/components/atoms/ProgressSpinner.vue';
  Vue.use(VueYouTubeEmbed)
  
  export default Vue.extend({
	components: {
		ProgressSpinner
	},
	data(){
		return {
			videoUrl: '',
			importing: false,
			importError: null
		}
	},
	watch: {
		videoUrl(){
			this.importYouTubeVideo();
		}
	},
	methods: {
		async importYouTubeVideo(){
			this.importError = null;
			this.importing = true;
			try{
				const result = await axios.get('/api/videos/import-from-url?url=' + this.videoUrl);
				const video = result.data;
				if(video.videoId){
					// redirect to the transcript stuff
					window.location.href = '/api/videos/amplify-transcript/' + video.videoId
				}else{
					// show error
					this.importError = 'Error importing'
					if(video.error){
						this.importError += ': ' + video.error
					}
				}
			} catch(e){
				console.log(e);
				this.importError = 'Error importing: ' + e
			}

			this.importing = false;
		}
	}
  });
  </script>
  <style scoped lang="less">
  @import '../less/variables';
  input{
	font-size: 1.5em;
	padding: 0.5em;
  }
  </style>
  